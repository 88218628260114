<template>
  <UiPopup
    :model-value="modelValue"
    title="To connect WhatsApp to your Inbox:"
    :loading="loading"
    size="medium"
    @update:model-value="emits('update:modelValue', false)"
  >
    <div class="flex flex-col gap-6 text-left">
      <ul class="flex flex-col gap-3">
        <li class="text-body">1. Open WhatsApp on your phone</li>
        <li class="text-body">
          2. Tap <span class="text-subhead-1">Menu</span>
          <UiIcon name="more-vertical" class="-mt-0.5 ml-1 inline-block rounded bg-black-10 text-black-70" /> or
          <span class="text-subhead-1">Settings</span>
          <UiIcon name="wa-settings" class="-mt-0.5 ml-1 inline-block rounded bg-black-10 p-0.5 text-black-70" /> and
          select
          <span class="text-subhead-1">Linked Devices</span>
        </li>
        <li class="text-body">3. Tap on <span class="text-subhead-1">Link a device</span></li>
        <li class="text-body">4. Point your phone to this screen to capture the QR code</li>
      </ul>
      <div class="mx-auto"><vue-qr :text="whatsappStore.getQrCode" :size="170"></vue-qr></div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { useWhatsappStore } from '@/store/whatsapp'

const whatsappStore = useWhatsappStore()
const emits = defineEmits(['update:modelValue', 'connected'])

type Props = {
  modelValue: boolean
}

defineProps<Props>()

const loading = ref(false)

watch(
  () => whatsappStore.getHasIntegration,
  (value: any) => {
    if (value) {
      emits('connected')
      emits('update:modelValue', false)
    }
  }
)
</script>

<style scoped></style>